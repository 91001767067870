import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { createRoutesFromChildren, matchRoutes } from "react-router";
import { useLocation, useNavigationType } from "react-router-dom";

import { determineMaximumScaleViewport } from "utils/meta";

// Determine if the device is an apple device and prevent zoom on input focus.
determineMaximumScaleViewport();

// Load accessibility tools in development mode.
if (import.meta.env.MODE === "development") {
    import("@axe-core/react").then((axe) => {
        axe.default(React, ReactDOM, 1000);
    });
}

// Force a reload when page is loaded from bfcache (casues issues when
// page is updated and the browser has an older version cached)
window.onpageshow = (event: PageTransitionEvent) => {
    if (event.persisted) {
        window.location.reload();
    }
};

if (import.meta.env.MODE === "production") {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
        ],
        tracesSampleRate: 1.0,
        release: import.meta.env.VITE_SOURCE_VERSION || "local-dev",
    });
}

const initStyles = async () => {
    await import("./assets/fonts/Inter/inter.css");
    await import("./index.css");
};
const initPolyfills = () => import("./polyfills");
const initApp = () =>
    import("./App").then(({ default: App }) => {
        ReactDOM.createRoot(
            document.getElementById("root") as HTMLElement,
        ).render(
            <React.StrictMode>
                <App />
            </React.StrictMode>,
        );
    });
const initi18n = () => import("./i18n");

const promises = [initStyles, initPolyfills, initApp, initi18n];

(async () => {
    for (const fn of promises) {
        await fn();
    }
})();
